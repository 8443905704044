<template >
  <common-header :title="$t('LP质押记录')" :isBg="true" :isOrder="true"></common-header>
  <div class="container">
    <div class="order-head flex-between">
      <span class="title">{{$t('数量')}}</span>
      <span class="title">{{$t('质押时间')}}</span>
    </div>
    <div class="list" v-if="orderList">
      <div class="card-box box-shadow" v-for="(item, index) in orderList" :key="index">
        <div class="box-tit flex-between">
          <div class="box-p">{{Bignumber(item.List.lpNumber).dividedBy(Math.pow(10, decimals)).toFixed()}}</div>
          <div class="box-p">{{$dateformat(Number(item.List.buyTime) * 1000, 'yyyy-mm-dd HH:MM')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonHeader from '@/components/CommonHeader'
import { reactive, toRefs, computed, watch } from "vue";
import {useStore} from "vuex";
import { useI18n } from "vue-i18n";
import { config } from "@/utils/config";
import pledgeAbi from "@/utils/pledge";
import tokenAbi from "@/utils/abi";
import Bignumber from "bignumber.js"
import { useRoute } from "vue-router";
export default {
  components: {
    CommonHeader
  },
  setup() {
    const store = useStore()
    const { t } = useI18n()
    const route = useRoute()
    const data = reactive({
      $t: t,
      address: computed(() => store.state.accounts[0]),
      web3: computed(() => store.state.provider),
      Bignumber: Bignumber,
      PledgeContract: null,
      TokenContract: null,
      pledgeNum: '',
      decimals: '',
      LPAllowance: '',
      balanceOf: '',
      planInfo: [],
      userInfos: [],
      orderList: [],
      planId: route.query.planId || '1',
      newTime: parseInt(Date.now()),
      initContract: () => {
        data.PledgeContract = new data.web3.eth.Contract(pledgeAbi, config.PledgeAddress)
        data.TokenContract = new data.web3.eth.Contract(tokenAbi, config.LPTokenAddress)
        data.initData()
      },
      initData: () => {
        data.getTokenInfo()
        data.PledgeList()
      },
      getTokenInfo: async() => {
        data.decimals = await data.TokenContract.methods.decimals().call()
      },
      PledgeList: async() => {
        data.orderList = []
        let num = await data.PledgeContract.methods.getOrderNum(data.address, data.planId).call()
        console.log(num)
        if (!Number(num)){
          return
        }
        for (var i = 0; i < Number(num); i++) {
          let List = await data.PledgeContract.methods.getOrder(data.address, data.planId, i).call()
          let earnNum = await data.PledgeContract.methods.earn(data.address, data.planId, i).call()
          console.log(earnNum, List.received)
          let allReward = Number(earnNum) + Number(List.received)
          data.orderList.push({List, allReward: allReward, earnNum: earnNum})
        }
      }
    })
    if (data.address) {
      data.initContract()
    }
     watch(() => data.address, (val) => {
      if (val) {
        data.initContract()
      }
    })
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
}
</script>
<style lang="less" scoped>
.container {
  background: url('~@/assets/images/bg01.png') no-repeat top center;
  background-size: 100% auto;
  background-attachment: fixed;
  .order-head {
    height: 44px;
    background: rgba(238, 245, 246, .26);
    border-radius: 10px;
    padding: 15px 12px;
    font-size: 15px;
    color: #FFFFFF;
    margin: 12px;
  }
  .card-box {
    margin: 0 12px 12px;
    background: #EEF5F6;
    border-radius: 10px;
    padding: 12px;
    position: relative;
    &.box-shadow {
      box-shadow: 0px 1px 13px 0px #383444;
    }
    .box-tit {
      font-size: 13px;
      color: @deep-color;
      line-height: 23px;
    }
    .box-num {
      font-size: 13px;
      color: @theme-color;
      font-weight: bold;
      line-height: 30px;
      margin-bottom: 8px;
      b {
        font-size: 25px;
      }
    }
    .box-p {
      font-size: 15px;
      color: #316F79;
      line-height: 23px;
      font-weight: bold;
    }
  }
}
</style>
<style lang="less">
.form-item {
  .van-field__control {
    color: #fff !important;
    font-size: 13px;
    &::placeholder {
      color: #EBEBEB!important;
    }
  }
}
</style>